import { render, staticRenderFns } from "./AdminsManagement.vue?vue&type=template&id=b436156a&scoped=true&"
import script from "./admins-management.ts?vue&type=script&lang=ts&"
export * from "./admins-management.ts?vue&type=script&lang=ts&"
import style0 from "./AdminsManagement.vue?vue&type=style&index=0&id=b436156a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b436156a",
  null
  
)

export default component.exports